import React from 'react'
import styled from 'styled-components'
import WelcomeSection from 'sections/WelcomeSection'
import TopNavigation from 'components/navigation/TopNavigation'
import ServicesSection from 'sections/ServicesSection'
import InnovationsSection from 'sections/InnovationsSection'
import PassionSection from 'sections/PassionSection'
import GallerySection from 'sections/GallerySection'
import LanguagesSection from 'sections/LanguagesSection'
import StandardsSection from 'sections/StandardsSection'

const App = () => {
  return (
    <AppContent>
      <TopNavigation />
      <WelcomeSection />
      <ServicesSection />
      <PassionSection />
      <InnovationsSection />
      <LanguagesSection />
      <StandardsSection />
      <GallerySection />
      {/* <ContactSection /> */}
      {/*<Footer />*/}
    </AppContent>
  )
}

export default App

const AppContent = styled.div`
  position: relative;
`
