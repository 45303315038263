import React from 'react'
import styled from 'styled-components'
import { mobileCss } from 'utils/rwd'
import standardyPDF from 'assets/Standardy_ochrony_małoletnich_- Smart School.pdf'

const StandardsSection = () => {
  return (
    <Wrapper>
      <Content>
        <Title>&bull; Standardy ochrony małoletnich w Smart School &bull;</Title>
        <Description>
          Bezpieczeństwo naszych uczniów jest dla nas priorytetem. Zapoznaj się z naszymi standardami ochrony małoletnich.
        </Description>
        <DownloadButton href={standardyPDF} download>
          Pobierz dokument PDF
        </DownloadButton>
      </Content>
    </Wrapper>
  )
}

export default StandardsSection

const Wrapper = styled.div`
  padding: 40px;
  background: #fff;

  ${mobileCss(`
    padding: 30px 20px;
  `)}
`

const Content = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  text-align: center;
`

const Title = styled.div`
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: bold;
  color: #16365b;
`

const Description = styled.div`
  font-size: 18px;
  margin-bottom: 30px;
  color: #16365b;
`

const DownloadButton = styled.a`
  display: inline-block;
  padding: 15px 30px;
  background: #ffcf00;
  color: #16365b;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background 0.2s;

  &:hover {
    background: #ffd700;
  }
` 